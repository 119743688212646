import { getAuth } from 'firebase/auth'
import { queryClient } from 'index'
import _, { transform, isEqual, isArray, isObject, isEmpty } from 'lodash'
import { serialize } from 'object-to-formdata'
import { parse, isDate } from 'date-fns'
import { IFullVital, IVitalReport, MEASUREMENT_TYPE, VITAL_TYPE } from 'types/entities'
import { ITableData } from 'types/common'
import { errorNotify } from 'components/Toaster/toster'
import {
	ALLOWED_ICON_FILES,
	getClientDetails,
	useDebouncedHandler,
	verifyImageType,
} from 'hooks/common'
import { FIELD_NAMES, FILE_UPLOAD_MESSAGES, LOCAL_STORAGE, PERMISSION_NAME } from 'const/common'
import { useField, useFormikContext } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePostalCodes } from 'hooks/react-query'
import { STATES_OPTIONS } from 'const/form'
import { ROLES, ROLES_NAMES } from 'const/auth'
import { getAllCountryTimezones, toDate } from './time'
import axios, { CancelTokenSource } from 'axios'
import { successNotify } from './toster'
import TableSortHeader from 'components/TableSortHeader'
import { APP_ROUTES } from 'const/router'
import { useParams } from 'react-router-dom'
import { useUrlQuery } from 'hooks/url'
import moment from 'moment-timezone'

export function toFormData<T>(data: T) {
	return serialize(data, { indices: true })
}
export async function resendRefreshToken({ navigate }: { navigate: any }) {
	const refreshToken = localStorage.getItem('AuthRefreshToken')
	const data = JSON.stringify({
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	})

	const config = {
		method: 'POST',
		url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: data,
	}
	try {
		const response = await axios(config)
		const newAccessToken = await response.data.access_token
		const newRefreshToken = await response.data.refresh_token
		localStorage.setItem('token', newAccessToken)
		localStorage.setItem('AuthRefreshToken', newRefreshToken)
		return newAccessToken
	} catch (error) {
		await signOut()
		navigate(APP_ROUTES.SIGN_IN)
	}
}

export const handleLogoUpload = (
	event: any,
	setFieldValue?: any,
	setImageUpload?: any,
	name?: string,
) => {
	const files = event.target.files[0]
	if (files.size > 10000000) {
		errorNotify("You can't upload image more than 10 MB")
	} else if (!verifyImageType(files?.type)) {
		errorNotify('You can upload only jpg,png and jpeg images')
	} else {
		if (name) {
			setFieldValue(name, files)
		} else {
			setFieldValue('image', files)
		}
		setImageUpload(files)
	}
}

export const handleDeleteImage = (props: any) => {
	props.setImageUpload('')
	props.setFieldValue(props.name, '')
}

export function filterEmptyObjectItems(values: { [key: string]: any }) {
	function internalClean(el: any) {
		return transform(el, function (result: { [key: string]: any }, value: any, key: string) {
			const isCollection = isObject(value)
			const cleaned = isCollection ? internalClean(value) : value

			if (isCollection && isEmpty(cleaned)) {
				return
			}

			isArray(result) ? result.push(cleaned) : (result[key] = cleaned)
		})
	}

	return isObject(values) ? internalClean(values) : values
}

/**
 * Get roles name
 */
export const getRolesName = (role: string | undefined) => {
	switch (role) {
		case ROLES.ADMIN:
			return ROLES_NAMES.ADMIN
		case ROLES.MA:
			return ROLES_NAMES.MA
		case ROLES.DOCTOR:
			return ROLES_NAMES.DOCTOR
		case ROLES.SUPER_ADMIN:
			return ROLES_NAMES.SUPER_ADMIN
		case ROLES.PROGRAM_MANAGER:
			return ROLES_NAMES.PROGRAM_MANAGER
		case ROLES.PARTNER:
			return ROLES_NAMES.PARTNER
		case ROLES.CARE_COORDINATOR:
			return ROLES_NAMES.CARE_COORDINATOR
		case ROLES.PROGRAM_COORDINATOR:
			return ROLES_NAMES.PROGRAM_COORDINATOR
		case ROLES.EPIDEMIOLOGIST:
			return ROLES_NAMES.EPIDEMIOLOGIST
		case ROLES.PROVIDER:
			return ROLES_NAMES.PROVIDER
		case ROLES.CHCT:
			return ROLES_NAMES.CHCT
		default: {
			return ''
		}
	}
}

//https://davidwells.io/snippets/get-difference-between-two-objects-javascript
/**
 * Find difference between two objects
 * @param {object} origObj - Source object to compare newObj against
 * @param {object} newObj - New object with potential changes
 * @return {object} differences
 */
export function difference(
	origObj: { [key: string]: any },
	newObj: { [key: string]: any },
	selectorByKey?: (key: string | number | symbol) => boolean,
) {
	function changes(newObj: { [key: string]: any }, origObj: { [key: string]: any }) {
		let arrayIndexCounter = 0
		return transform(newObj, function (result: { [key: string]: any }, value, key) {
			if (!isEqual(value, origObj[key])) {
				const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
				if (selectorByKey && selectorByKey(resultKey)) {
					result[resultKey] =
						isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
				}
				if (!selectorByKey) {
					result[resultKey] =
						isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
				}
			}
		})
	}
	return changes(newObj, origObj)
}

export function buildSortParams<T extends object>(param = '') {
	if (typeof param === 'string') {
		const result = param?.match(/^-/)
		if (result) {
			const key = param.slice(1)
			return [key, 'DESC'] as [keyof T, 'DESC']
		}
	}
	return [param, 'ASC'] as [keyof T, 'ASC']
}

export const handleFileUpload = async (event: any, fileUpload: any) => {
	try {
		const file = event.target.files
		const response = await fileUpload({ file: file[0] })
		successNotify(FILE_UPLOAD_MESSAGES.SUCCESS)
		return response.data?.fileUrl
	} catch (err: any) {
		errorNotify(FILE_UPLOAD_MESSAGES.ERROR)
	}
}

export const deleteFile = async (deleteUpload: any, path: any, successPopup = true) => {
	try {
		const response = await deleteUpload(path)
		if (successPopup) {
			successNotify(response?.data?.message)
		}
	} catch (err: any) {
		errorNotify(FILE_UPLOAD_MESSAGES.ERROR)
	}
}

export async function signOut(cb?: () => void) {
	queryClient.removeQueries()
	queryClient.clear()
	cb ? cb() : ''
	localStorage.clear()
	localStorage.removeItem('token')
	localStorage.removeItem('AuthRefreshToken')
	localStorage.removeItem('lastActivity')
	localStorage.removeItem('isOver')
	sessionStorage.clear()
	await getAuth().signOut()
	// ClearAllCache()
}

export function parseDateString(value: string, originalValue: string) {
	const parsedDate = isDate(originalValue)
		? originalValue
		: parse(originalValue, 'yyyy-MM-dd', toDate())

	return parsedDate
}

export function timezoneDropdownOptions() {
	return (
		getAllCountryTimezones()?.map((item, index) => {
			const tzName = moment.tz(item).format('z')
			return {
				value: item,
				label: `${item} - ${tzName}`,
			}
		}) || []
	)
}

export const handleTypeNumber = (setFieldValue: any, fieldName: string, value: number) => {
	if (value < 0.01) {
		setFieldValue(fieldName, null)
	} else {
		setFieldValue(fieldName, value)
	}
}

export const handleNPI_EIN = (
	setFieldValue: any,
	fieldName: string,
	value: string,
	lengthCheck: number,
) => {
	if (value < '0.01') {
		setFieldValue(fieldName, null)
	} else {
		if (value.length <= lengthCheck) {
			setFieldValue(fieldName, value)
		}
	}
}

export const formatDateTimeHourMinuteSecond = (date: any) => {
	if (!date) return ''
	let hours = date.getHours()
	const minutes = date.getMinutes().toString().padStart(2, '0')
	const seconds = date.getSeconds().toString().padStart(2, '0')
	const ampm = hours >= 12 ? 'PM' : 'AM'
	hours = hours % 12 || 12 // Convert to 12-hour format and handle midnight
	const formattedHours = hours.toString().padStart(2, '0')
	return `${formattedHours}:${minutes}:${seconds} ${ampm}`
}

export function sortByTimeCallback(a: Date | string, b: Date | string) {
	if (typeof a === 'string') {
		if (a && b) {
			if (toDate(a) < toDate(b)) {
				return -1
			}
			if (toDate(a) > toDate(b)) {
				return 1
			}
			return 0
		} else {
			return 0
		}
	}
	if (a && b) {
		if (a < b) {
			return -1
		}
		if (a > b) {
			return 1
		}
		return 0
	} else {
		return 0
	}
}

export default function calculatePts(px: number) {
	return px * 0.43
}

export function buildBloodPressureValue(
	item: Partial<IFullVital> | undefined | Partial<IVitalReport>,
) {
	let value: string | number = '---'

	const sys =
		item?.measurements?.find((m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_SYS)?.value || '---'
	const dia =
		item?.measurements?.find((m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_DIA)?.value || '---'
	const pulse =
		item?.measurements?.find((m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_HB)?.value || '---'
	value = `${sys}/${dia}/${pulse}`

	return value
}

export function filterCb(vitalType: VITAL_TYPE) {
	return (item: ITableData) => {
		if (item.type === vitalType) {
			if (item.type === VITAL_TYPE.BLOOD_PRESSURE) {
				const sys = item?.measurements?.find(
					(m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_SYS,
				)?.value
				const dia = item?.measurements?.find(
					(m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_DIA,
				)?.value
				const pulse = item?.measurements?.find(
					(m) => m.type === MEASUREMENT_TYPE.BLOOD_PRESSURE_HB,
				)?.value
				return !!sys && !!dia && !!pulse
			}
			return true
		}
		return false
	}
}
//https://isotropic.co/how-to-format-a-date-as-dd-mm-yyyy-in-javascript/
export function format(inputDate: Date) {
	let date, month

	date = inputDate.getDate()
	month = inputDate.getMonth() + 1
	const year = inputDate.getFullYear()

	date = date.toString().padStart(2, '0')

	month = month.toString().padStart(2, '0')

	return `${month}/${date}/${year}`
}

export function scrollToError() {
	const element = document.querySelector('.error-message')
	if (element) {
		element?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest',
		})
	}
}

export function useZipFieldDependencies(isManually: boolean) {
	const params = useParams<{
		id: string
	}>()
	const [param] = useUrlQuery<any>()
	const id = params.id || param?.id
	const [{ value }] = useField<string>(FIELD_NAMES.POSTAL_CODE)
	const [zipCodeValue, setZipCodeValue] = useState('')
	const { setFieldValue } = useFormikContext()
	const handler = useDebouncedHandler(
		useCallback((zipCode?: string) => {
			setZipCodeValue(zipCode || '')
		}, []),
		500,
	)

	const { data } = usePostalCodes({
		zipCode: zipCodeValue,
		enabled: !isManually && !!zipCodeValue,
		suspense: false,
	})
	const zipCode = data?.data[0]
	const prevValue = useRef<string>('')

	useEffect(() => {
		if (prevValue.current !== value) {
			handler(value)
			prevValue.current = value
		}
	}, [handler, value])

	useEffect(() => {
		if (!isManually && zipCode) {
			setFieldValue(FIELD_NAMES.CITY, zipCode?.primaryCity)
			const state = STATES_OPTIONS.find((item) => item.abbreviation === zipCode?.state)
			setFieldValue(FIELD_NAMES.STATE, {
				value: state?.label,
				label: state?.label,
			})
			setFieldValue(FIELD_NAMES.CITY, zipCode?.primaryCity ?? '')
		}
	}, [isManually, setFieldValue, zipCode])
}

export function download_File(
	endPoint?: string,
	fileName?: string,
	fileType?: string,
	params?: any,
	source?: CancelTokenSource,
	setIsDownload?: any,
) {
	//This code for moudlar appraoch for Pdf
	// if (params?.id === 'member') {
	// params.id = _.capitalize(params?.id)
	// }
	const filteredParams = _.pickBy(params, _.identity)
	setIsDownload(true)
	const checkPath = `${endPoint}`
	const promise = axios
		.get(checkPath, {
			params: filteredParams,
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${fileName}.${fileType}`)

			// Simulate a click event to trigger the download
			document.body.appendChild(link)
			link.click()
			setIsDownload(false)

			// Clean up the temporary URL object
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
		.catch(() => {
			setIsDownload(false)
			errorNotify(`Error downloading File ${fileType}`)
			// console.error('Error downloading PDF:', error)
		})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}
	return promise
}

export const getLocalStorageDetails = (keyName: string): any | null => {
	const keyDetails = localStorage?.getItem(keyName)
	return keyDetails ?? ''
}
export const setLocalStorageDetails = (keyName: string, value: string): any | null => {
	localStorage?.setItem(keyName, value)
}

export const onParseJSON = (jsonString: string | null | undefined): any | null => {
	try {
		return jsonString ? JSON.parse(jsonString) : null
	} catch (error) {
		return null
	}
}
export const onStringify = (data: any) => {
	try {
		return JSON.stringify(data)
	} catch (error) {
		return ''
	}
}

export function convertToLowerCase(input: string | boolean | null) {
	if (typeof input !== 'string') {
		return ''
	}
	return input?.toLowerCase()
}
//  This function will be used to get dynamic table columns headers
export function onGetTableColumns(dynamicColumn: any) {
	const columnDetail = Array.isArray(dynamicColumn)
		? dynamicColumn?.map((columnObj: any, index: number) => {
				const {
					clientColumn: {
						clientColumnDisplayName,
						column: { isSortable, name },
					},
				} = columnObj
				const accessorValue = `col${index + 1}`
				const headerValue = isSortable
					? ({ ...rest }) => <TableSortHeader {...rest}>{clientColumnDisplayName}</TableSortHeader>
					: clientColumnDisplayName
				const columnDetailsObj = {
					Header: headerValue,
					accessor: accessorValue,
					name: name,
					sortable: isSortable,
				}

				return columnDetailsObj
		  })
		: []
	return columnDetail
}

//  This function will be used to get dynamic table body columns details
export function onGetTablebodyColumn(apiObj: any, bodyobj: any, columnHeader: any) {
	if (!columnHeader || !Array.isArray(columnHeader)) {
		console.error('Invalid columnHeader provided')
		return {} // or handle the error in a way appropriate for your use case
	}

	return columnHeader?.reduce((acc: any, columnObj: any) => {
		// const paramName = columnObj?.name?.toLowerCase()
		const paramName = columnObj?.name
		const apiParamValue = apiObj?.[paramName]
		const keyValue = typeof apiParamValue === 'string' ? apiParamValue : ''
		// console.log(paramName, 'paramName')
		const columnDetail = bodyobj[paramName] || keyValue || ''
		// const columnDetail = bodyobj[paramName] || apiObj?.[paramName] || ''
		acc[columnObj.accessor] = columnDetail
		return acc
	}, {})
}
//  This function is used to get grid section details
export function onGetTableSectionDetail(screenDetail: any) {
	const tableSectionDetail = screenDetail?.allScreenSection?.grid || null
	return tableSectionDetail
}

//  This function is used to get grid section for child details
export function onGetTableChildSectionDetail(screenDetail: any) {
	const tableSectionDetail = screenDetail?.allSectionDetails?.grid || null
	return tableSectionDetail
}

//  This function is used to get grid section details
export function onGetCustomSectionDetail(screenDetail: any, key: string | boolean) {
	const keyName = convertToLowerCase(key)
	const tableSectionDetail = screenDetail?.allScreenSection?.[keyName] || null
	return tableSectionDetail
}

//  This function is used to get Filter section columns detail
export function onGetFilterSectionColumns(screenDetail: any) {
	const providerFilterColumn = screenDetail?.allScreenSection?.filter?.providerColumns || []
	return providerFilterColumn
}

//  This function is used to get form section detail
export function onGetFormFieldData(formSectionDetail: any) {
	const providerForms = formSectionDetail?.providerForms
	//  If only one step then return first step data otherwise return all section form
	const result =
		providerForms && providerForms?.length === 1
			? providerForms[0]?.providerFormSections
			: providerForms
	return result
}
//  This function is used to get form field validation detail
export function onGetFieldValidationDetail(formFieldsData: any) {
	const valid = formFieldsData?.map((formObj: any) => {
		const { providerFields } = formObj
		const providerFieldDetail = providerFields?.map((fieldObj: any) => {
			const {
				clientField: {
					field: { slug, name, dataType, fieldType },
					isRequired,
				},
			} = fieldObj
			const fieldDetail = { ...fieldObj, slug, name, dataType, fieldType, isRequired }
			return fieldDetail
		})
		return providerFieldDetail
	})
	const allFields = valid?.flat()
	return allFields
}
//  This function is used to get form section detail
export function onGetFormFieldDetail(screenDetail: any, formSectionName: string) {
	const formSectionObj = screenDetail?.allScreenSection?.[formSectionName]

	const formDetail = formSectionObj ? onGetFormFieldData(formSectionObj) : []
	return formDetail
}
//  This function is used to get form validation detail
export function onGetFormValidationDetail(screenDetail: any, formSectionName: string) {
	//
	const formDetails = onGetFormFieldDetail(screenDetail, formSectionName)
	const formFieldValidationData = onGetFieldValidationDetail(formDetails)
	return formFieldValidationData
	// const valid = formDetails?.map((formObj: any) => {
	// 	const { providerFields } = formObj
	// 	const providerFieldDetail = providerFields?.map((fieldObj: any) => {
	// 		const {
	// 			clientField: {
	// 				field: { slug, name, dataType, fieldType },
	// 				isRequired,
	// 			},
	// 		} = fieldObj
	// 		const fieldDetail = { ...fieldObj, slug, name, dataType, fieldType, isRequired }
	// 		return fieldDetail
	// 	})
	// 	return providerFieldDetail
	// })
	// const allFields = valid?.flat()
	// console.log(allFields, 'valid')
	// return allFields
}
//  This function is used to get form section Button detail
export function onGetFormButton(formDetails: any) {
	const lastFormSection = formDetails?.length > 0 ? formDetails?.length - 1 : 0
	let formButtonDetail = []
	if (lastFormSection >= 0) {
		const providerButtons = formDetails[lastFormSection]?.providerButtons
		if (providerButtons?.length > 0) {
			formButtonDetail = providerButtons
		} else if (
			lastFormSection > 0 &&
			formDetails[lastFormSection - 1]?.providerButtons?.length > 0
		) {
			formButtonDetail = formDetails[lastFormSection - 1]?.providerButtons
		} else if (
			lastFormSection > 1 &&
			formDetails[lastFormSection - 2]?.providerButtons?.length > 0
		) {
			formButtonDetail = formDetails[lastFormSection - 1]?.providerButtons
		}
	}
	return formButtonDetail
}
// export function onGetFormButton(formDetails: any) {
// 	const lastFormSection = formDetails?.length - 1

// 	const formButtonDetail = formDetails[lastFormSection]?.providerButtons || []
// 	return formButtonDetail
// }

//  This function is used to get column name or field name of filter section
export function onGetFilterColumnName(filterObj: any) {
	const columnName = filterObj?.clientColumn?.column?.name ?? ''
	return columnName
}
//  This function is used to get filter section's column details
export function onGetFilterColumnDetail(filterObj: any) {
	const filterColumnObj = {
		fieldName: filterObj?.clientColumn?.column?.name ?? '',
		fieldLabel: filterObj?.clientColumn?.clientColumnLabel ?? '',
		fieldPlaceholder: filterObj?.clientColumn?.clientColumnPlaceholder ?? '',
	}
	return filterColumnObj
}

export const onEmailHandleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
	if (event?.code === 'Space') {
		event.preventDefault()
	}
}
export const onVerifyArray = (array: any) => {
	if (!Array.isArray(array)) {
		return [] // If it's not an array, return an empty array
	} else {
		return array // If it's already an array, return it as is
	}
}

export function onCheckSectionAllowed(screenData: any, formSectionName: string) {
	const sectionObj = screenData?.allScreenSection?.[formSectionName]
	const sectionName = sectionObj?.clientSection?.clientSectionName ? true : false
	return sectionName
}
export function onGetSectionName(screenData: any, formSectionName: string) {
	const sectionObj = screenData?.allScreenSection?.[formSectionName]
	const sectionName = sectionObj?.clientSection?.clientSectionName ?? ''
	return sectionName
}

export function onGetScreenName(screenData: any) {
	const displayScreenName = screenData?.clientScreen?.clientScreenName ?? ''
	return displayScreenName
}
export function onCreateEndpointsWithClientid(endPoint: string) {
	// const clientId = getClientDetails()?.clientId
	const clientdata = getClientDetails()
	const clientId = clientdata?.clientId
	const endPointUrl = `${endPoint}/${clientId}`
	return endPointUrl
}
//  Function used to trim
export function onTrim(value: string) {
	const trimValue = value?.trim() ?? ''
	return trimValue
}
export interface IPermissionObj {
	assign?: string | undefined
	add?: string | undefined
	clone?: string | undefined
	delete?: string | undefined
	edit?: string | undefined
	exportpdf?: string | undefined
	exportexcel?: string | undefined
	exportvital?: string | undefined
	exportmember?: string | undefined
}
export function onCheckPermissionInModule(
	modulePermissionDetail: IPermissionObj,
	permissionType: keyof IPermissionObj,
) {
	const permissionAllowed = modulePermissionDetail?.[permissionType] ? true : false
	return permissionAllowed
}

// This function is used to check edit permission of modules
export function onCheckEditPermissionInModule(modulePermissionDetail: IPermissionObj) {
	const permissionCheck = onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.EDIT)
	return permissionCheck
}
// This function is used to check delete permission of modules
export function onCheckDeletePermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.DELETE)
}
export function onCheckExportPDFPermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.EXPORT_PDF)
}
export function onCheckExportVitalPermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.EXPORT_VITAL)
}

export function onCheckExportMembersPermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.EXPORT_MEMBER)
}

// This function is used to check add permission of modules
export function onCheckAddPermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.ADD)
}
// This function is used to check clone permission of modules
export function onCheckClonePermissionInModule(modulePermissionDetail: IPermissionObj) {
	return onCheckPermissionInModule(modulePermissionDetail, PERMISSION_NAME?.CLONE)
}

// This function is used to get client screen path name
export function getScreenPath(screenObj: any) {
	return screenObj?.clientScreen?.screen?.slug ?? ''
}
// This function is used to get client screen displayName
export function getScreenDisplayName(screenObj: any) {
	return screenObj?.clientScreen?.screen?.screenName ?? ''
}

//  Function use to get screen section name
export const getScreenSectionName = ({
	screenDetail,
	sectionsDetails,
}: {
	screenDetail: any
	sectionsDetails: string[]
}) => {
	const sectionNameDetail: { [key: string]: any } = {}
	sectionsDetails?.forEach((section: string) => {
		const key = `${section}SecName`
		sectionNameDetail[key] = onGetSectionName(screenDetail, section)
	})

	return sectionNameDetail
}
//  Function use to get screen section status
export const getStatusOfScreenSections = ({
	screenDetail,
	sectionsDetails,
}: {
	screenDetail: any
	sectionsDetails: string[]
}) => {
	const allowedSection = screenDetail?.allowedSectionDetail
	const sectionStatusObj: { [key: string]: any } = {}
	sectionsDetails?.forEach((section: string) => {
		// const key = `show${section.charAt(0).toUpperCase() + section.slice(1)}`
		const key = `show${section}`
		sectionStatusObj[key] = allowedSection?.[section] !== undefined
	})
	return sectionStatusObj
}

export const convertCamelCaseToTitleCase = (input: string) => {
	// Split the input string at uppercase letters
	const words = input?.replace(/([A-Z])/g, ' $1').trim()

	// Convert the first letter of each word to uppercase
	const titleCaseWords = words[0]?.toUpperCase() + words?.slice(1)
	// Join the words with spaces
	return titleCaseWords
}

export const validateTypeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
	const regex = /^\d+$/
	const value = e?.target?.value
	if (regex.test(value) || !value) {
		return value
	}
}

export const handleLength = (
	setFieldValue: any,
	fieldName: string,
	value: string,
	lengthCheck: number,
) => {
	if (value < '0.01') {
		setFieldValue(fieldName, null)
	} else {
		if (value.length <= lengthCheck) {
			setFieldValue(fieldName, value)
		}
	}
}
export const validateDecimalTypeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
	const regex = /^\d+(\.\d+)?$/
	const value = e?.target?.value
	if (regex.test(value) || !value) {
		return value
	}
}

export const getTokenUserId = () => {
	const token: any = getLocalStorageDetails(LOCAL_STORAGE?.TOKEN)
	if (token === '') {
		return ''
	} else {
		const getToken = JSON.parse(Buffer.from(token?.split('.')[1], 'base64').toString())
		return getToken?.user_id
	}
}

export const getUploadDocumentIcon = (fileExtension = '') => {
	if (ALLOWED_ICON_FILES.DOCUMENTS.includes(fileExtension)) {
		return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/2048px-.docx_icon.svg.png'
	} else if (ALLOWED_ICON_FILES.EXCELS.includes(fileExtension)) {
		return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/.xlsx_icon.svg/2048px-.xlsx_icon.svg.png'
	} else if (ALLOWED_ICON_FILES.VIDEOS.includes(fileExtension)) {
		return 'https://icons.veryicon.com/png/o/file-type/file-type-1/mp4-icon.png'
	} else if (ALLOWED_ICON_FILES.PDFS.includes(fileExtension)) {
		return 'https://w7.pngwing.com/pngs/49/1018/png-transparent-pdf-computer-icons-others-miscellaneous-text-rectangle.png'
	} else if (ALLOWED_ICON_FILES.IMAGE.includes(fileExtension)) {
		return ''
	}
}

export const bmiCalculator = (height: number, weight: number) => {
	const bmi = ((weight / (height * height)) * 703).toFixed(1)
	return bmi
}
